@import '../../style/custonVariable';
.ReferralMembers{
    padding: 15px;
    
}

.back_btn{
    border: 1px solid transparent;
    border-radius: 4px;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.1);
    height: 34px;
    width: 7%;
    background-color: #23b7e5;
    color: white;
    margin-top: -51px;
    float: right;
    
    @media only screen and (max-width: 1845px){
        width:100px;
        // margin-right: 14px;
       
    }
    // @media only screen and (max-width: 700px){
    //     width: 115px;
      
    // }
    // @media only screen and (max-width: 800px){
    //     width: 115px;
       
    // }
    // @media only screen and (max-width:1771px){
    //     width: 115px;
      
    // }

    i{
        // margin-right: 12px;
       
        background-color: rgba(0, 0, 0, 0.1);
        width: 47px;
        height: 35px;
        margin: -7px -90px;
        margin-right:15px;
        line-height: 34px;
        text-align: center;
        border-radius: 2px 0 0 2px;

        @media only screen and (max-width: 1845px){
            margin-right: -2px;
        }
       

    }
    //  @media only screen and (max-width: 700px){
    //     width: 38px;
    //     margin: -7px -85px;
    //     }
    


}




.tableData{
    .tableAvatarDetails{
        display: grid;
        grid-template-columns: 35px 1fr;
        align-items: center;
        grid-gap: 10px;
        div{
            position: relative;
            .profileImage{
                width: 35px;
                height: 35px;
                border-radius: 40%;
            }
            .active{
                position: absolute;
                z-index: 1;
                left: -3px;
                top: -1px;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                &.active{
                    background-color: #27c24c;
                }
                &.inactive{
                    background-color: red;
                }
            }
            .userName{
                line-height: 30px;
                margin-bottom: -2px;
            }
            .userid{
                font-size: 14px;
                line-height: 1px;
            }
        }
    }
    .action{
        background-color: lightgrey;
        color: black;    
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
    }
}
.DownlineMembers{
    padding: 15px;
    padding-top: 35px;
}
.titlepanel{
    margin-bottom: 20px;
    border-color: #dee5e7;
    .panelBody{
        padding: 15px;
        .count{
            display: inline-block;
            text-align: center;
            padding: 5px 10px 4px 10px;
            border-radius: 2px;
            min-width: 120px;
            margin-right: 5px;
            @media only screen and (max-width: 600px){
                margin-right: 7px;
            }
            &.colorPurple{
                background: #dad5f5;
                .text{
                    font-size: 14px;
                    &.textPurple{
                        color: #35297d;
                    }
                }
                .countText{
                    font-size: 1.5em;
                    &.textPurple{
                        color: #35297d;
                    }
                }
            }
            &.colorGreen{
                background: #d3e0d4;
                .text{
                    font-size: 14px;
                    &.textPurple{
                        color: #366139;
                    }
                }
                .countText{
                    font-size: 1.5em;
                    &.textPurple{
                        color: #366139;
                    }
                }
            }
        }
        .searchRight{
            display: grid;
            align-items: end;
            grid-template-columns: 260px 200px;
            justify-content: end;
            @media (max-width: 600px){
                grid-template-columns: 1fr;
                justify-content: start;
                margin-top: 15px;
            }
        
            
            .paddingSmall{
                padding-left:10px;
                padding-right: 0;
                .formGroup{
                    margin: 0 ;
                    position: relative;
                    @media only screen and (max-width: 600px){

                    }
                   

              
                  
                   
               
                    .Button{
                        $button-padding : 6px 12px;
                        $button-font-size : 14px;
                        $button-margin-bottom : 0;
                        height: $inputField-height;
                        margin-right: 2px;
                        &.BtnPrimary{
                            border-color: $color-primary;
                            background-color: $color-primary;
                            @media only screen and (max-width: 600px){
                                margin-right: 80px;
                                margin-top: -35px;
                                float: right;

                            }
                        
                        }
                        &.BtnInfo{
                            border-color: $color-info;
                            background-color: $color-info;
                            @media only screen and (max-width: 600px){
                                float: right;
                                margin-top: -35px;
                            }
                         
                           
                        }

                    }
                    .dropDownSelect{
                        height: $inputField-height;
                        @media only screen and (max-width: 600px){
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}
.paginationContainer{
    display: flex;
    float: right;
    list-style: none;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    cursor: pointer;
 
    a{
        padding: 10px;
        border-radius: 5px;
        // border: 1px solid #6c7ac9;
        color: #6c7ac9;
        margin-left: 10px;
        background-color: #ededed;
    }
    .Link{
        font-weight: bold;
    }
    .active{
        a{
            color: #fff;
            background: #6c7ac9;
        }
    }
}

//tree view
.TreeViewContainer{
    padding: 15px;
    legend{
        display: block;
        width: 100%;
        padding: 0;
        margin-bottom: 21px;
        padding-bottom: 12px;
        font-size: 18px;
        line-height: inherit;
        color: #333;
        border: 0;
        border-bottom: 1px solid #e5e5e5;
    }
    .treeview{
        overflow-x: scroll;
        min-height: 600px;
        .listGroup{
            margin-bottom: 0px;
            border-radius: 2px;
            li{
                background-color: #58666e00;
                &::before{
                    content: "";
                    position: absolute;
                    top: -38px;
                    left: 0px;
                    border-left: 2px dashed #a2a5b5;
                    width: 1px;
                    height: 100%;
                }
                &::after{
                    content: "";
                        position: absolute;
                        border-top: 2px dashed #a2a5b5;
                        top: 40px;
                        left: 0;
                        width: 4rem;
                }
                &:last-child{
                    &::before{
                        height: 80px;
                    }
                }
                .expand{
                    position: absolute;
                    top: 30px;
                    left: 50px;
                    z-index: 99;
                    color: #7266ba;
                    cursor: pointer;
                    i{
                        top: 30%;
                        background: #fff;
                        border-radius: 50px;
                    }
                }
                .commentCard{
                    display: flex;
                    flex-direction: column;
                    padding-left: 3rem;
                    color: #333;
                    .figure{
                        display: flex;
                        // justify-content: start;
                        background: #f6f6f6;
                        box-shadow: 5px 5px 8px #cecece, -5px -5px 8px #e5ecee;
                        border-radius: 3px;
                        border: initial;
                        min-width: 0;
                        width: max-content;
                        word-wrap: break-word;
                        .image{
                            width: 4rem;
                            height: 4rem;
                            object-fit: cover;
                            border-radius: 50%;
                            margin-right: 1rem;
                            // box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
                        }
                        .figcaption{
                            display: flex;
                            flex-direction: column;
                            // justify-content: start;
                            padding: 0 1rem;
                            margin: auto 0;
                            text-align: center;
                            .username{
                                color: #7266BA;
                                font-weight: bold;
                                line-height: 1;
                            }
                            .fullname{
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                cursor: help;
                                font-size: .75em !important;
                                font-weight: initial !important;
                                color: #58666e;
                            }
                        }
                        .userLevel{
                            background: #cacbd31c;
                            border-radius: 50px;
                            padding: 5px;
                            width: 45px;
                            height: 45px;
                            margin-right: 10px;
                            border: 1px solid #bcc4cf2b;
                            line-height: 1.2em;
                            .level{
                                font-size: 16px;
                            }
                            .level_text{
                                font-size: 12px;
                            }
                        }
                        .tooltipInfo{
                            border: none;
                            border-radius: 50px;
                            width: 45px;
                            height: 45px;
                            padding: 5px;
                            margin-right: 6px;
                            font-size: 17px;
                            color: #23b7e5;
                            box-shadow: 5px 5px 8px #eee, -5px -5px 8px #f0f1f1;
                            background: #f6f6f6;
                        }
                        .help{
                            cursor: help;
                            margin: auto;
                        }
                    }
                }
            }
            .listGroupItem{
                border-color: #f0f3f4 !important;
                padding: 11px 12px 15px !important;
                position: relative;
                display: block;
                margin-bottom: -1px;
                border: 1px solid #ddd;
            }
        }
        ul{
            list-style: none;
            padding-left: 42px;
        }
    }
}
.tooltipContainer{
    // background-color: #40b7e5;
    // min-height: 300px;
    width: 224px;
    padding: 0;
}
.arrow{
    height: 1rem;
    position: absolute;
    width: 1rem;
    &::before{
        position: absolute;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #40b7e5;
        left: 50%;
        margin-left: -10px;
        top: -10px;
    }
    // background-color: red !important;
}

.tooltip {
    position: relative;
    display: inline-block;
    
    // border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    font-family: 'poppinslight';
    font-size: small;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 17px;
    right: -51%;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }